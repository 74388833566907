@headerBj: #00000033;
@popoutBj: linear-gradient(180deg, #383838 0%, #3f3f3fe0 100%);
@bodyBj:#010101;
@footerBj:#141414FF;
@activeColor:#3D75EE;
@defaultColor:#fff;
@footerTextColor:#CCCCCC;
@headerHight:60px;
@footerHight:185px;
.textBox() {
  position: relative;
  text-align: left;
  background: linear-gradient(180deg, #282828 9.22%, #3e3e3e 70.69%, #282828 128.49%);
  &::before{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(90deg, #70eafb 15.2%, #70eafb00 88.19%);
    content: '';
  }
  &::after{
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(270deg, #3d75ee 15.2%, #3d75ee00 87.41%);
    content: '';
  }
};

.myH1(){
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  font-weight: 700;
}

.myH2{
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
  margin-top: 10px;
}

.app-header{
  background: @headerBj !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: @headerHight !important;
  display: flex;
  justify-content: center;
  z-index: 999;
  .app-header-content{
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .app-header-content-logo{
      width: 95px;
      height: 18px;
    }
    .app-header-nav{
      flex: 1;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 50px;
      overflow-x: scroll;
      padding: 0 !important;
      .app-link{
        &:last-child {
          padding-right: 0;
          &::after{
            display: none;
          }
        }
       
      }
      .app-link{
        min-width: 76px;
        overflow: hidden;
        position: relative;
        & > div{
          font-size: 12px;
          line-height: 25px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: @defaultColor;
          opacity: .5;
        }
        
        
      }
      .active-app-link{
        & > div{
          opacity: 1;
          // color: @activeColor;
        }
      }
    }
  }
  
}