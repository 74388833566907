@headerBj: #00000033;
@popoutBj: linear-gradient(180deg, #383838 0%, #3f3f3fe0 100%);
@bodyBj:#010101;
@footerBj:#141414FF;
@activeColor:#3D75EE;
@defaultColor:#fff;
@footerTextColor:#CCCCCC;
@headerHight:60px;
@footerHight:185px;
.textBox() {
  position: relative;
  text-align: left;
  background: linear-gradient(180deg, #282828 9.22%, #3e3e3e 70.69%, #282828 128.49%);
  &::before{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(90deg, #70eafb 15.2%, #70eafb00 88.19%);
    content: '';
  }
  &::after{
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(270deg, #3d75ee 15.2%, #3d75ee00 87.41%);
    content: '';
  }
};

.myH1(){
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  font-weight: 700;
}

.myH2{
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
  margin-top: 10px;
}

.app-footer {
    background-color: @footerBj !important;
    // position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    z-index: 9999;
    border-top: 1px solid #141414FF;
    .app-footer-box{
        width: 1400px;
        height:@footerHight;
        color: @footerTextColor;
        font-size: 14px;
        
        .app-footer-box-mode-1{
            width: 100%;
            height: 115px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .app-footer-box-mode-1-left{
                display: flex;
                align-items: center;
                .app-footer-box-mode-1-left-item{
                    color: #fff;
                    font-size: 16px;
                    margin-right: 60px;
                    div{
                        &:nth-child(1){
                            font-size: 14px;
                            opacity: .5;
                        };
                        &:nth-child(2){
                            margin-top: 3px;
                            font-weight: 700;
                        }
                    }
                }
            }
            .app-footer-box-mode-1-right{
                width: 80px;
                height: 37px;
                line-height: 37px;
                text-align: center;
                color: #FFf;
                background-color: @activeColor;
                border-radius: 4px;
                cursor: pointer;
                display: none;
            }
        }

        .app-footer-box-mode-2{
            width: 100%;
            height: 70px;
            border-top: 1px solid #ffffff1a;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #FFf;
            opacity: .5;
            font-size: 14px;
            .app-footer-box-mode-2-right{
                span{
                    margin-right: 20px;
                    &:nth-child(1){
                        .bottom-authority-href{
                            color: #fff;
                        }
                        cursor: pointer;
                        position: relative;
                        &::after{
                            position: absolute;
                            content: '·';
                            font-weight: 700;
                            right: -12px;
                        }
                    }
                }
            }
        }

        .bottom-authority-href{
            color: @activeColor;
        }
    }
}
   