/* 全局配置 */

* {
  -webkit-overflow-scrolling: touch; /* 允许独立滚动区域，解决IOS上的非body元素滚动条滚动时卡顿 */
  -webkit-tap-highlight-color: transparent; /* 覆盖IOS上用户点击连接时的默认高亮颜色 */
  outline: none;
}
body {
  min-width: 1200px;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
/* 禁止换行,末尾省略 */
.all_nowarp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 强制换行 */
.all_warp {
  word-break: break-all;
  word-wrap: break-word;
}
.all_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 美化滚动条 */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: transparent;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  background-color: #333;
}

