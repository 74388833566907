@headerBj: #00000033;
@popoutBj: linear-gradient(180deg, #383838 0%, #3f3f3fe0 100%);
@bodyBj:#010101;
@footerBj:#141414FF;
@activeColor:#3D75EE;
@defaultColor:#fff;
@footerTextColor:#CCCCCC;
@headerHight:60px;
@footerHight:185px;
.textBox() {
  position: relative;
  text-align: left;
  background: linear-gradient(180deg, #282828 9.22%, #3e3e3e 70.69%, #282828 128.49%);
  &::before{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(90deg, #70eafb 15.2%, #70eafb00 88.19%);
    content: '';
  }
  &::after{
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(270deg, #3d75ee 15.2%, #3d75ee00 87.41%);
    content: '';
  }
};

.myH1(){
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  font-weight: 700;
}

.myH2{
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
  margin-top: 10px;
}

@import './assets/default.less';

.app {
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: @bodyBj;
  .container {
    width: 1200px;
    min-height: calc(100vh - @headerHight - @footerHight);
    margin: 730px auto 0px;
    overflow-y: auto;
  }
  .ant-back-top{
    inset-block-end:200px ;
  }
}